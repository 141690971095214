import BaseService from '@/services/BaseService'

class SuppliersService extends BaseService {
  constructor() {
    super()
  }

  async getSuppliers(opts = {}) {
    this.endPoint = 'api/v2/supplier'
    const response = await this.get(opts)
    return response.data
  }

  async getOneSupplier(id: any, opts = {}) {
    this.endPoint = `api/v2/supplier/${id}`
    const response = await this.get(opts)
    return response.data
  }

  async setSupplier(data: any, params = {}) {
    this.endPoint = 'api/v2/supplier'
    return await this.post(data, params)
  }

  async updateSupplier(id: any, data: any, params = {}) {
    this.endPoint = 'api/v2/supplier'
    return this.postOne(id, data, params)
  }

  async getClient(opts = {}) {
    this.endPoint = 'api/v2/users/excludeadmin'
    return await this.get(opts)
  }

  async getCountry(opts = {}) {
    this.endPoint = 'api/v2/country'
    return await this.get(opts)
  }

  async deleteDeleteSupplier(id: any) {
    this.endPoint = 'api/v2/supplier'
    return await this.delete(id)
  }

}
export default new SuppliersService()